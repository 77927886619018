import React from 'react';
import Hero from './components/Hero';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ProductInfo from './components/ProductInfo'; // Adiciona a importação do novo componente
import WhatsAppInfo from './components/WhatsAppInfo'; // Importa o novo componente

function App() {
  return (
    <div>
      <Hero />
      <Features />
      <ProductInfo /> {/* Adiciona o novo componente aqui */}
      <Pricing />
      <WhatsAppInfo />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
