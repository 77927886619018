import React, { useState } from 'react';
import './Testimonials.css'; // Inclua ou ajuste este arquivo CSS conforme necessário

function Testimonials() {
  const testimonials = [
    { name: 'João Silva', feedback: 'Este chatbot transformou nosso atendimento ao cliente!' },
    { name: 'Ana Oliveira', feedback: 'Incrivelmente fácil de usar e eficaz.' },
    { name: 'Carlos Pereira', feedback: 'Uma mudança radical para nossa equipe.' },
    { name: 'Maria Santos', feedback: 'Altamente recomendável.' },
    { name: 'Pedro Almeida', feedback: 'O melhor investimento que fizemos este ano.' },
    { name: 'Juliana Costa', feedback: 'Suporte e funcionalidade fantásticos.' },
    { name: 'Felipe Silva', feedback: 'Amigável e poderoso.' },
    { name: 'Fernanda Lima', feedback: 'Superou nossas expectativas.' },
    { name: 'Ricardo Souza', feedback: 'Valor incrível pelo dinheiro.' },
    { name: 'Camila Rodrigues', feedback: 'Intuitivo e eficaz.' },
    { name: 'Bruno Martins', feedback: 'Uma ferramenta essencial para nossos negócios.' },
    { name: 'Patrícia Almeida', feedback: 'Confiável e eficiente.' },
    { name: 'Marcos Ferreira', feedback: 'Um must-have para qualquer equipe.' },
    { name: 'Juliana Oliveira', feedback: 'Fácil de integrar e usar.' },
    { name: 'Roberto Santos', feedback: 'Ótimo suporte ao cliente.' },
    { name: 'Claudia Mendes', feedback: 'Transforma os fluxos de trabalho.' },
    { name: 'André Silva', feedback: 'Simples e poderoso.' },
    { name: 'Beatriz Costa', feedback: 'Altamente funcional e amigável.' },
    { name: 'Eduardo Lopes', feedback: 'Eficiente e confiável.' },
    { name: 'Tatiane Santos', feedback: 'Ferramenta excepcional para produtividade.' },
    { name: 'Leandro Souza', feedback: 'Centrada no usuário e eficaz.' },
    { name: 'Vanessa Pereira', feedback: 'Absolutamente vale o investimento.' }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3;
  const totalSlides = Math.ceil(testimonials.length / itemsToShow);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  return (
    <div className="bg-gray-100 py-20">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-8">O que nossos usuários dizem</h2>
        <div className="relative">
          {/* Botão de navegação à esquerda */}
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 bg-gray-200 text-gray-700 rounded-full z-10"
            aria-label="Slide anterior"
          >
            &lt;
          </button>
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-500"
              style={{ transform: `translateX(-${currentIndex * (100 / totalSlides)}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-lg flex-shrink-0"
                  style={{ width: `${100 / itemsToShow}%` }}
                >
                  <h3 className="text-xl font-bold mb-2">{testimonial.name}</h3>
                  <p>"{testimonial.feedback}"</p>
                </div>
              ))}
            </div>
          </div>
          {/* Botão de navegação à direita */}
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 bg-gray-200 text-gray-700 rounded-full z-10"
            aria-label="Próximo slide"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
