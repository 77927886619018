import React from 'react';

function Contact() {
  return (
    <div className="bg-white py-20">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-around gap-6 mb-12">
          <div className="w-full max-w-xs p-6 bg-blue-100 border border-blue-300 rounded-lg text-center">
            <h3 className="text-xl font-semibold mb-4 text-blue-600">Fale com um especialista</h3>
            <p className="mb-4">Converse com nossos especialistas para obter mais informações.</p>
            <a
              href="mailto:specialist@example.com"
              className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
            >
              Enviar Email
            </a>
          </div>
          <div className="w-full max-w-xs p-6 bg-green-100 border border-green-300 rounded-lg text-center">
            <h3 className="text-xl font-semibold mb-4 text-green-600">Perguntas Gerais</h3>
            <p className="mb-4">Tire suas dúvidas sobre nossos serviços e produtos.</p>
            <a
              href="mailto:info@example.com"
              className="inline-block px-6 py-3 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700"
            >
              Enviar Email
            </a>
          </div>
          <div className="w-full max-w-xs p-6 bg-yellow-100 border border-yellow-300 rounded-lg text-center">
            <h3 className="text-xl font-semibold mb-4 text-yellow-600">Trabalhe Conosco</h3>
            <p className="mb-4">Saiba mais sobre oportunidades de trabalho e estágio.</p>
            <a
              href="/trabalhe-conosco"
              className="inline-block px-6 py-3 bg-yellow-600 text-white font-semibold rounded-lg hover:bg-yellow-700"
            >
              Ver Oportunidades
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
