import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { SiVisa, SiMastercard, SiPaypal, SiPix } from 'react-icons/si';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-between mb-8">
          <div className="w-full md:w-1/3 mb-6 md:mb-0 text-center md:text-left">
            <h2 className="text-2xl font-semibold mb-2">ChatFácil</h2>
            <p className="text-gray-400">Sua solução inteligente para comunicação eficiente.</p>
          </div>
          
          <div className="w-full md:w-1/3 flex justify-center mb-6 md:mb-0">
            <div className="flex space-x-4">
              <a href="https://www.facebook.com" className="text-gray-400 hover:text-white">
                <FaFacebook size={24} />
              </a>
              <a href="https://twitter.com" className="text-gray-400 hover:text-white">
                <FaTwitter size={24} />
              </a>
              <a href="https://www.instagram.com" className="text-gray-400 hover:text-white">
                <FaInstagram size={24} />
              </a>
              <a href="https://www.linkedin.com" className="text-gray-400 hover:text-white">
                <FaLinkedin size={24} />
              </a>
              <a href="https://www.youtube.com" className="text-gray-400 hover:text-white">
                <FaYoutube size={24} />
              </a>
            </div>
          </div>
          
          <div className="w-full md:w-1/3 flex flex-col items-center md:items-end">
            <div className="flex space-x-4 mb-4">
              <a href="/privacy-policy" className="text-gray-400 hover:underline">Privacy Policy</a>
              <a href="/terms-of-service" className="text-gray-400 hover:underline">Terms of Service</a>
            </div>
            <div className="flex space-x-4 mb-4">
              <h3 className="text-lg font-semibold">Formas de Pagamento</h3>
              <div className="flex space-x-4">
                <SiVisa size={24} className="text-gray-400 hover:text-white" />
                <SiMastercard size={24} className="text-gray-400 hover:text-white" />
                <SiPaypal size={24} className="text-gray-400 hover:text-white" />
                <SiPix size={24} className="text-gray-400 hover:text-white" />
              </div>
            </div>
          </div>
        </div>

        <p className="text-gray-400 text-sm text-center">&copy; 2024 ChatFácil. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

export default Footer;
