import React from 'react';
import './ProductInfo.css'; // Certifique-se de que o caminho está correto

const ProductInfo = () => {
  return (
    <>
      {/* Seção 1: Imagem à esquerda, Texto à direita */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-16">
            <div className="flex-1 max-w-lg">
              <img 
                src="https://cdn.prod.website-files.com/5e2f2362e479664d4e15718a/63e567aa6d23ee5e59513c5d_AVO-095-Mockup-Chats-01-vPT-p-800.png" 
                alt="Chatbot ajudando clientes em diferentes canais" 
                className="w-full h-auto rounded-lg"
              />
            </div>
            <div className="flex-1 max-w-lg">
              <h2 className="text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl">
                Automatize o atendimento com o seu chatbot
              </h2>
              <p className="mt-4 text-base text-gray-500">
                Se seus clientes preferem interagir através do WhatsApp, nosso chatbot está pronto para responder instantaneamente. Seja enviando mensagens, nosso sistema compreende e reage de forma eficiente, estando disponível a qualquer momento do dia.
              </p>
              <p className="mt-4 text-base text-gray-500">
                Além disso, você pode configurar e personalizar a experiência do usuário de maneira simples e intuitiva, sem necessidade de programação, usando nossa plataforma sem código.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Seção 2: Texto à esquerda, Imagem à direita */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row-reverse items-center justify-between gap-16">
            <div className="flex-1 max-w-lg">
              <img 
                src="https://cdn.prod.website-files.com/5e2f2362e479664d4e15718a/63ed746b63fb624bdd223bff_AVO-095-Mockup-Chats-02-vPT-p-800.png" 
                alt="Integração do chatbot com plataformas" 
                className="w-full h-auto rounded-lg"
              />
            </div>
            <div className="flex-1 max-w-lg">
              <h2 className="text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl">
                Integre o chatbot com facilidade às suas ferramentas favoritas

              </h2>
              <p className="mt-4 text-base text-gray-500">
              Nosso chatbot é compatível com ferramentas populares como Dialogflow, n8n, webhooks e Typebot. Isso significa que você pode conectar seu chatbot a diferentes plataformas de maneira simples e rápida, tornando a comunicação com seus clientes mais eficaz.
              </p>
              <p className="mt-4 text-base text-gray-500">
              Com essas integrações, você pode automatizar tarefas, responder perguntas com precisão e criar uma experiência personalizada para cada usuário. Isso garante uma comunicação contínua e eficiente, aumentando a satisfação do cliente e otimizando seus processos.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Seção 3: Imagem à esquerda, Texto à direita */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-16">
            <div className="flex-1 max-w-lg">
              <img 
                src="https://cdn.prod.website-files.com/5e2f2362e479664d4e15718a/6406186be45b21691acc41b4_AVO-095-Mockup-Chats-03-vPT-p-800.png" 
                alt="Inicie conversas proativas com seu chatbot" 
                className="w-full h-auto rounded-lg"
              />
            </div>
            <div className="flex-1 max-w-lg">
              <h2 className="text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl">
                Inicie conversas proativas com seu chatbot
              </h2>
              <p className="mt-4 text-base text-gray-500">
                Dê o primeiro passo na comunicação com seus clientes usando nosso chatbot. Crie campanhas proativas e envie mensagens automatizadas para manter seus clientes informados e engajados.
              </p>
              <p className="mt-4 text-base text-gray-500">
                Desde atualizações sobre novos recursos até lembretes de eventos, nosso sistema garante que suas mensagens sejam entregues de forma eficaz e personalizada.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Seção 4: Texto à esquerda, Imagem à direita */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row-reverse items-center justify-between gap-16">
            <div className="flex-1 max-w-lg">
              <img 
                src="https://cdn.prod.website-files.com/5e2f2362e479664d4e15718a/640618b0420d092a368caee6_AVO-095-Mockup-Chats-04-vPT-p-800.png" 
                alt="Integração com atendimento humano" 
                className="w-full h-auto rounded-lg"
              />
            </div>
            <div className="flex-1 max-w-lg">
              <h2 className="text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl">
                Combine chatbot com suporte humano
              </h2>
              <p className="mt-4 text-base text-gray-500">
                Para questões mais complexas, nosso chatbot pode transferir a conversa para um atendente humano. Assim, você garante que as questões mais delicadas sejam tratadas com a atenção e cuidado necessários.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductInfo;
