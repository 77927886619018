import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const faqs = [
  {
    question: "O que é o Dashboard do chatbot?",
    answer: "O Dashboard oferece uma visão geral do desempenho do seu chatbot, mostrando estatísticas e métricas chave, como interações, atendimentos e atividades recentes."
  },
  {
    question: "Como funciona o atendimentos?",
    answer: "A funcionalidade de atendimentos permite gerenciar e monitorar as conversas entre o chatbot e os usuários, facilitando o acompanhamento das interações e a resolução de problemas."
  },
  {
    question: "O que é o Kanban e como posso utilizá-lo?",
    answer: "O Kanban é uma ferramenta de gestão visual que ajuda a organizar e acompanhar tarefas e processos. No nosso aplicativo, você pode usar o Kanban para gerenciar fluxos de trabalho e garantir que as tarefas sejam concluídas de forma eficiente."
  },
  {
    question: "O que são as Respostas Rápidas?",
    answer: "Respostas Rápidas são mensagens predefinidas que o chatbot pode enviar automaticamente em resposta a perguntas frequentes. Você pode configurar e personalizar essas respostas para melhorar a eficiência do atendimento."
  },
  {
    question: "Como funcionam as Tarefas?",
    answer: "A funcionalidade de Tarefas permite criar e atribuir tarefas relacionadas ao gerenciamento do chatbot, garantindo que todas as atividades necessárias sejam realizadas e acompanhadas."
  },
  {
    question: "O que é a lista de Contatos e como é utilizada?",
    answer: "A lista de Contatos armazena informações sobre os usuários que interagem com o chatbot, permitindo que você gerencie e acesse facilmente os detalhes de cada contato para um atendimento mais personalizado."
  },
  {
    question: "Como funcionam os Agendamentos?",
    answer: "Os Agendamentos permitem que você defina e gerencie compromissos e eventos importantes. Você pode configurar lembretes e notificações para garantir que todas as atividades sejam realizadas conforme planejado."
  },
  {
    question: "O que são Tags e como ajudam na organização?",
    answer: "As Tags são rótulos que você pode atribuir às conversas ou contatos para facilitar a organização e a categorização. Elas ajudam a segmentar e filtrar informações de forma mais eficiente."
  },
  {
    question: "Como funciona o Chat Interno?",
    answer: "O Chat Interno permite que sua equipe se comunique diretamente dentro da plataforma, facilitando a colaboração e a troca de informações sem sair do aplicativo."
  },
  {
    question: "O que são Campanhas e como utilizá-las?",
    answer: "Campanhas são conjuntos de mensagens e estratégias de marketing que você pode criar e gerenciar para promover produtos ou serviços e engajar seus usuários de forma mais eficaz."
  },
  {
    question: "Como o Open AI é integrado ao chatbot?",
    answer: "O Open AI é integrado ao chatbot para fornecer respostas mais inteligentes e contextuais, melhorando a qualidade das interações e oferecendo suporte avançado com base em inteligência artificial."
  },
  {
    question: "Quais integrações estão disponíveis?",
    answer: "O aplicativo suporta diversas integrações com outras ferramentas e plataformas, permitindo que você conecte seu chatbot com serviços externos para expandir suas funcionalidades e melhorar a eficiência."
  },
  {
    question: "Como é gerenciada a lista de arquivos?",
    answer: "A lista de arquivos permite que você armazene e acesse documentos e recursos importantes diretamente no aplicativo, facilitando o gerenciamento e a organização de arquivos relacionados ao chatbot."
  },
  {
    question: "O que são Filas e como elas ajudam no atendimento?",
    answer: "As Filas ajudam a organizar e priorizar as interações com os usuários, garantindo que as solicitações sejam atendidas de forma ordenada e eficiente, melhorando a gestão do fluxo de trabalho."
  },
  {
    question: "Como funciona o Chatbot e quais são suas principais funcionalidades?",
    answer: "O Chatbot é o núcleo do aplicativo, automatizando as interações com os usuários e fornecendo respostas rápidas e precisas. Ele pode ser configurado para executar diversas funções, como atendimento ao cliente, envio de mensagens automáticas e muito mais."
  }
];

const AccordionItem = ({ question, answer, isOpen, onClick }) => (
  <div className={`bg-white shadow-sm rounded-lg border border-gray-300 ${isOpen ? 'border-blue-500' : ''}`}>
    <div
      className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-150 ease-in-out border-b border-gray-300"
      onClick={onClick}
    >
      <h3 className="text-lg font-medium text-gray-700">{question}</h3>
      <FontAwesomeIcon
        icon={isOpen ? faChevronUp : faChevronDown}
        className="text-gray-500 transition-transform duration-150"
      />
    </div>
    {isOpen && (
      <div className="p-4 text-gray-600 text-sm">
        <p>{answer}</p>
      </div>
    )}
  </div>
);

const WhatsAppInfo = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="bg-gray-100 text-gray-800 py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-2xl font-bold mb-8 text-center">Perguntas Frequentes</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-4xl mx-auto">
          {faqs.map((faq, index) => (
            <AccordionItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={expandedIndex === index}
              onClick={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppInfo;
