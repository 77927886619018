import React from 'react';
import { MdSupport, MdAccessTime } from 'react-icons/md';
import { AiOutlineApi } from 'react-icons/ai';
import { FiSettings, FiPhone } from 'react-icons/fi';
import { BiCustomize } from 'react-icons/bi';
import { HiOutlineChartBar } from 'react-icons/hi';
import { FaRegClipboard, FaBullhorn, FaComments } from 'react-icons/fa';

function Features() {
  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">

          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-600 sm:text-4xl">
            O que o ChatFácil pode fazer por você?
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Atendimento 24/7 */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <MdAccessTime className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Atendimento 24/7</h3>
            <p className="mt-2 text-base text-gray-500">
              Respostas instantâneas a qualquer hora.
            </p>
          </div>
          {/* Integração Fácil */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <AiOutlineApi className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Integração Fácil</h3>
            <p className="mt-2 text-base text-gray-500">
              Funciona com suas plataformas favoritas.
            </p>
          </div>
          {/* Automação Inteligente */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <FiSettings className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Automação Inteligente</h3>
            <p className="mt-2 text-base text-gray-500">
              Simplifique tarefas repetitivas.
            </p>
          </div>
          {/* Personalização */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <BiCustomize className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Personalização</h3>
            <p className="mt-2 text-base text-gray-500">
              Customize respostas e fluxos de conversa.
            </p>
          </div>
          {/* Análise de Dados */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <HiOutlineChartBar className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Análise de Dados</h3>
            <p className="mt-2 text-base text-gray-500">
              Relatórios detalhados sobre as interações.
            </p>
          </div>
          {/* Lista de Contatos */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <FiPhone className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Lista de Contatos</h3>
            <p className="mt-2 text-base text-gray-500">
              Gerencie e mantenha uma lista de contatos para facilitar a comunicação.
            </p>
          </div>
          {/* Kaban */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <FaRegClipboard className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Kaban</h3>
            <p className="mt-2 text-base text-gray-500">
              Organize suas tarefas e projetos com eficiência.
            </p>
          </div>
          {/* Campanhas */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <FaBullhorn className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Campanhas</h3>
            <p className="mt-2 text-base text-gray-500">
              Crie e gerencie campanhas de marketing diretamente.
            </p>
          </div>
          {/* Chat Interno */}
          <div className="relative p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105">
            <FaComments className="mx-auto text-4xl text-indigo-500" />
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Chat Interno</h3>
            <p className="mt-2 text-base text-gray-500">
              Facilite a comunicação interna com sua equipe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
