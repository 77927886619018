import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faCalendarAlt, faRobot, faUser, faUsers, faChartLine, faStar } from '@fortawesome/free-solid-svg-icons';

const featuresData = {
  light: [
    { icon: faUser, text: '1 Número' },
    { icon: faUsers, text: '3 Atendentes' },
    { icon: faChartLine, text: 'Dashboard facilitado' },
    { icon: faRobot, text: 'Auto atendimento / Robô' },
    { icon: faStar, text: 'Campanhas' },
    { icon: faCalendarAlt, text: 'Agendamentos' },
    { icon: faRocket, text: 'Mensagens ilimitadas' },
    { icon: faStar, text: 'Clientes ilimitados' },
  ],
  pro: [
    { icon: faUser, text: '2 Números' },
    { icon: faUsers, text: '5 Atendentes' },
    { icon: faChartLine, text: 'Dashboard facilitado' },
    { icon: faRobot, text: 'Auto atendimento / Robô' },
    { icon: faStar, text: 'Campanhas' },
    { icon: faCalendarAlt, text: 'Agendamentos' },
    { icon: faRocket, text: 'Mensagens ilimitadas' },
    { icon: faStar, text: 'Clientes ilimitados' },
    { icon: faStar, text: 'Open.ai' },
  ],
  premium: [
    { icon: faUser, text: '3 Números' },
    { icon: faUsers, text: '10 Atendentes' },
    { icon: faChartLine, text: 'Dashboard facilitado' },
    { icon: faRobot, text: 'Auto atendimento / Robô' },
    { icon: faStar, text: 'Campanhas' },
    { icon: faCalendarAlt, text: 'Agendamentos' },
    { icon: faRocket, text: 'Mensagens ilimitadas' },
    { icon: faStar, text: 'Clientes ilimitados' },
    { icon: faStar, text: 'Open.ai' },
    { icon: faStar, text: 'Typebot' },
  ],
};

const plansData = [
  {
    title: 'Light',
    price: { monthly: 'R$ 67.00/mês', quarterly: 'R$ 170.00/tri' },
    features: featuresData.light,
    links: { monthly: 'https://pay.kiwify.com.br/xzxb18k', quarterly: 'https://pay.kiwify.com.br/nEE6fvJ' },
  },
  {
    title: 'Pro',
    price: { monthly: 'R$ 97.00/mês', quarterly: 'R$ 240.00/tri' },
    features: featuresData.pro,
    links: { monthly: 'https://pay.kiwify.com.br/VEIQiC2', quarterly: 'https://pay.kiwify.com.br/iNr773a' },
  },
  {
    title: 'Premium',
    price: { monthly: 'R$ 147.00/mês', quarterly: 'R$ 370.00/tri' },
    features: featuresData.premium,
    links: { monthly: 'https://pay.kiwify.com.br/tBXPYHX', quarterly: 'https://pay.kiwify.com.br/TFNDBxq' },
  },
];

const PricingCard = ({ title, price, features, link, onClick }) => (
  <div className="bg-white text-blue-600 m-4 p-6 rounded-lg shadow-lg max-w-xs w-full flex flex-col justify-between">
    <div>
      <h3 className="text-2xl font-bold mb-2">{title}</h3>
      <p className="text-3xl font-bold mb-4">{price}</p>
      <ul className="pl-0 text-left">
        {features.map((feature, idx) => (
          <li key={idx} className="mb-2 flex items-center">
            <FontAwesomeIcon icon={feature.icon} className="mr-3" />
            <span>{feature.text}</span>
          </li>
        ))}
      </ul>
    </div>
    <button
      className="bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 mt-6 rounded-lg"
      onClick={onClick}
      aria-label={`Escolher plano ${title}`}
    >
      Escolher Plano
    </button>
  </div>
);


function Pricing() {
  const [paymentPeriod, setPaymentPeriod] = useState('monthly');

  const handlePlanSelection = useCallback((link) => {
    window.location.href = link;
  }, []);

  return (
    <div className="bg-blue-600 text-white py-20">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-8">Nossos Planos</h2>
        <div className="mb-6">
          <button
            className={`py-2 px-4 rounded-l-lg ${paymentPeriod === 'monthly' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            onClick={() => setPaymentPeriod('monthly')}
            aria-label="Plano mensal"
          >
            Mensal
          </button>
          <button
            className={`py-2 px-4 rounded-r-lg ${paymentPeriod === 'quarterly' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            onClick={() => setPaymentPeriod('quarterly')}
            aria-label="Plano trimestral"
          >
            Trimestral
          </button>
        </div>
        <div className="flex flex-wrap justify-center">
          {plansData.map((plan, index) => (
            <PricingCard
              key={index}
              title={plan.title}
              price={plan.price[paymentPeriod]}
              features={plan.features}
              link={plan.links[paymentPeriod]}
              onClick={() => handlePlanSelection(plan.links[paymentPeriod])}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Pricing;
