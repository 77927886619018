import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import logo from './logo.png'; // Caminho para a logo PNG

function Hero() {
  return (
    <div className="relative bg-blue-600 text-white py-20">
      {/* Contêiner dos botões e links */}
      <div className="absolute top-6 right-4 md:right-10 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
        {/* Seção para Login e Cadastro */}
        <div className="flex space-x-2 mb-4">
          <a
            href="https://app.chatfacil.net/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300 text-white py-2 px-4 rounded-lg text-sm">
              Login
            </button>
          </a>
          <a
            href="https://app.chatfacil.net/signup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-200 text-blue-600 py-2 px-4 rounded-lg text-sm">
              Cadastrar
            </button>
          </a>
        </div>
      </div>

      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center">
        {/* Primeira Div: Texto e Botão */}
        <div className="flex flex-col w-full md:w-1/2 justify-center items-start text-left mb-8 md:mb-0">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-4">
            Revolucione seu atendimento no WhatsApp com o ChatFácil!
          </h1>
          <p className="text-lg md:text-xl mb-8">
            Nosso chatbot avançado com IA ajuda você a agilizar as interações com os clientes sem esforço.
          </p>
          <a
            href="https://wa.me/5521979495074" // Substitua com o URL desejado
            target="_blank" // Abre o link em uma nova aba
            rel="noopener noreferrer" // Melhora a segurança ao abrir links em uma nova aba
          >
            <button className="bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300 text-white py-3 px-8 rounded-lg text-lg">
              Testar agora
            </button>
          </a>
        </div>

        {/* Segunda Div: Logo */}
        <div className="w-full md:w-1/2 text-center">
          <img
            src={logo}
            alt="ChatZap Logo" // Descrição mais detalhada
            className="w-64 md:w-80 mx-auto transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
          />
        </div>
      </div>

      {/* Ícone flutuante do WhatsApp */}
      <a
        href="https://wa.me/5521979495074" // Substitua com o número de telefone desejado
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-4 right-4 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-colors"
      >
        <FaWhatsapp size={30} />
      </a>
    </div>
  );
}

export default Hero;
